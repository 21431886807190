const getters = {
    loginToken: state => state.app.token,
    nickName: state => state.app.nickName,
    roles: state => {
        const roles = state.app.roles
        if(roles !== ""){
            return JSON.parse(roles)
        }
        return null
    }
}
export default getters